import {WatchlistApp} from '@quantgate-systems/watchlist';
import queryString from "query-string";
import { useState } from 'react';


function App() {

  let values = queryString.parse(window.location.search);
  const [symbols, setSymbols] = useState([]);

  return (
    <WatchlistApp
      symbols={symbols}
      onSymbolsAdded={(symbol) => console.log(symbol)}
      brokerName="demo-mode"
      credentials={{UserName: 'qgsi_testing@quantgatesystems.com', Password: '12345678!A'}}
      onItemClick={(item) => {
        //alert(JSON.stringify(item))
        alert('Please login or create a free account')
      }}
      miniFilter
      customCardBg={values.bg ? values.bg : ""}
      customCardFg={values.fg ? values.fg : ""}
    />
  );
}

export default App;
